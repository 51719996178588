<template>
  <div class="main-box">
    <a-breadcrumb>
      <a-breadcrumb-item @click="$router.go(-1)"
        ><router-link to="/"
          >首页</router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item style="color: #15b7dd">{{
        $route.query.name
      }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="tabs">
      <p
        class="tabItem"
        @click="tabChange(item.id)"
        :class="{ active: item.id === tabCode }"
        v-for="(item, index) in tabMenu"
        :key="index"
      >
        {{ item.title }}
      </p>
    </div>
    <div class="contentList">
      <div class="contentItem" v-for="(item, index) in list" :key="index">
        <img :src="item.listPhoto" alt="" />
        <div class="content">
          <div class="left">
            <p class="name" @click="routerPush(item)">{{ item.productName }}</p>

            <!-- 线下培训时间 -->
            <p
              class="teacherName"
              v-if="item.openStartTime && item.openEndTime"
            >
              <img
                width="11"
                height="14"
                src="@/assets/image/index/roup980.png"
                alt=""
              />
              {{ item.openStartTime.substring(0,11) }} - {{ item.openEndTime.substring(0,11) }}
            </p>
            <!-- 线下培训地点 -->
            <p class="teacherName" v-if="item.province&&item.city">
              <img
                width="11"
                height="14"
                src="@/assets/image/index/roup979.png"
                alt=""
              />
              {{ item.province }} {{ item.city }} {{ item.district? '| '+  item.district : "" }}
            </p>
            <div class="navs" v-else-if="item.productType==1 || item.productType==4 || item.couponPrice">
              <span class="type" v-if="item.productType == 1"
                >{{ item.categoryName }} 丨视频数量：{{ item.classHour }}个</span
              >
            </div>
          </div>
          <div class="right">
            <div class="price">
              <p class="new">
                <span v-if="item.couponPrice"><span class="new_point">￥</span>{{managePrice(item.couponPrice)[0]}}<span class="new_point">.{{managePrice(item.couponPrice)[1]}}</span></span>
                <span class="free" v-else>免费</span>
              </p>
              <p class="old" v-if="item.original && item.couponPrice">
                ￥{{ item.originalPrice.toFixed(2) }}
              </p>
            </div>
            <div
              class="button2"
              v-if="item.productType == 1"
              @click="
                $router.push(
                  '/knowledgeMall/courseDetail?productId=' +
                    $AES.encode_data(item.productId + '') +
                    '&code=' +
                    item.productCode +
                    '&name=' +
                    $route.query.name
                )
              "
            >
              查看详情
              <img src="@/assets/image/Group 5446.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <a-spin class="spin" v-show="loading" />
      <a-pagination
        v-show="total"
        :hideOnSinglePage="true"
        show-quick-jumper
        :default-current="1"
        :total="total"
        @change="onChange"
      />
    </div>
    <Shopwarning :visible="visible" :fontText="fontText" />
    <Sidebar ref="sidebar"/>
  </div>
</template>

<script>
import Sidebar from "@/components/sidebar.vue";
export default {
  // 可用组件的哈希表
  components: { Sidebar },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      id: null, //父级id
      code: null, //父级code
      visible: false,
      fontText: "", // 文本
      tabMenu: [], // 二级分类
      tabCode: null, // tabId
      pageNum: 1,
      pageSize: 10,
      total: 0,
      list: [],
      loading: false,
    };
  },
  // 事件处理器
  methods: {
    // 价格处理
    managePrice(con) {
      let txt = (con * 1).toFixed(2).split('.')
      return txt
    },
    font(e){
      return e.replace(/\<[^>]+>/g,'')
    },
    routerPush(e) {
      if (e.productType == 1) {
        this.$router.push(
          "/knowledgeMall/courseDetail?productId=" +
            this.$AES.encode_data(e.productId + "") +
            "&code=" +
            e.productCode + "&name=" + this.$route.query.name
        );
      } else if (e.productType == 2 || e.productType == 3) {
        this.$router.push(
          "/knowledgeMall/curriculumDetail?productId=" +
            this.$AES.encode_data(e.productId + "") + "&name=" + this.$route.query.name
        );
      } else if (e.productType == 4) {
        this.$router.push(
          "/knowledgeMall/examDetail?productId=" +
            this.$AES.encode_data(e.productId + "") + "&name=" + this.$route.query.name
        );
      }
    },
    // tab切换
    tabChange(e) {
      if(e == this.tabCode){return}
      this.tabCode = e;
      this.pageNum = 1;
      this.list = [];
      this.total = 0;
      this.getShopList();
    },
    // 分页
    onChange(pageNumber) {
      this.pageNum = pageNumber;
      this.list = [];
      this.getShopList();
    },
    // 获取二级分类
    getChildType() {
      this.$ajax({
        url: "/hxclass-pc/ProductColumn/list",
        method: "get",
        params: {
          columnCode: 'AppHomeCourse'
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.tabMenu = res.data;
          this.tabCode = this.id?this.id:res?.data[0].id;
          console.log(this.tabCode)
          this.getShopList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getShopList() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-pc/ProductColumnRelation/client/list",
        method: "get",
        params: {
          columnId: this.tabCode, // 分类编码
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.list = res.data.records;
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    if(this.$route.query.id){
      this.id = Number(this.$AES.decode_data(this.$route.query.id));
    }
    this.getChildType(); // 获取二级分类
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.main-box {
  padding-top: 80px;
  padding-bottom: 60px;
  .tabs {
    display: inline-block;
    padding: 0 40px;
    margin-top: 22px;
    background-color: rgba(21, 183, 221, 0.1);
    .tabItem {
      display: inline-block;
      padding: 0 38px;
      line-height: 38px;
      font-size: 18px;
      color: #666666;
      margin-right: 20px;
      cursor: pointer;
    }
    .tabItem:last-child {
      margin: 0;
    }
    .active {
      color: #ffffff;
      background-color: #15b7dd;
    }
  }
  ::v-deep.ant-pagination {
    text-align: right;
    padding-right: 29px;
    padding-top: 45px;
  }
  .contentList {
    margin-top: 24px;
    padding: 20px 30px;
    min-height: 300px;
    border-radius: 5px;
    background: #ffffff;
    position: relative;
    
    .contentItem {
      background: #ffffff;
      border-radius: 5px;
      padding: 20px;
      border-bottom: 1px solid #e1e1e1;
      transition: all 0.5s;
      display: flex;
      >img {
        width: 120px;
        height: 120px;
        border-radius: 4px;
        transition: all 0.5s;
        background-repeat: no-repeat;
        object-fit: cover;
      }
      .content {
        padding: 8px 24px;
        flex: 1;
        display: flex;
        justify-content: space-between;
        .left {
          width: 580px;
          .name {
            cursor: pointer;
            font-size: 20px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #333333;
            line-height: 30px;
          }
          .navs {
            display: flex;
            // justify-content: space-between;
            align-items: center;
            height: 24px;
            overflow: hidden;
            flex-wrap: wrap;
            margin-top: 16px;
            word-wrap: break-word;
            word-break: normal;
            line-height: 21px;
            span {
              display: inline-block;
            }
            .type {
              font-size: 14px;
              font-family: PingFangMedium;
              color: #1e97b4;
              line-height: 21px;
              margin-right: 16px;
            }
            .nav {
              font-size: 12px;
              font-family: PingFangMedium;
              color: #EC6C01;
              line-height: 18px;
              border-radius: 4px;
              border: 1px solid #EC6C01;
              padding: 2px 8px;
              font-weight: 400;
              margin-right: 8px;
            }
          }
          .teacherName {
            margin-top: 12px;
            font-size: 14px;
            font-family: PingFangMedium;
            color: #666666;
            line-height: 21px;
          }
          .slogan {
            margin-top: 26px;
            font-size: 18px;
            font-family: PingFangMedium;
            color: #333333;
            line-height: 24px;
          }
          .introduce {
            margin-top: 26px;
            font-size: 16px;
            font-family: PingFangMedium;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
            position: relative;
            // display: flex;
            // flex-wrap: wrap;
            .backGround{
              padding-right: 24px;
              background-image: url(~@/assets/image/tag.png);
              background-size: 100% 100%;
            }
            span {
              white-space: nowrap;
              height: 21px;
              padding: 0 0 0 3px;
              text-align: center;
              display: inline-block;
              font-size: 14px;
              font-family: PingFangMedium;
              font-weight: 600;
              color: #ffffff;
              line-height: 24px;
              background-color: rgba(255, 120, 0, 0.8);
              > span {
                font-size: 20px;
                line-height: 21px;
                align-items: center;
              }
            }
            span:nth-child(2){
              margin-right: 20px;
            }
            .white {
              width: 0;
              height: 0;
              border-bottom: 24px solid white;
              border-left: 24px solid transparent;
              position: absolute;
              top: 3px;
              left: 95px;
            }
          }
        }
        .right {
          width: 200px;
          .price {
            display: flex;
            align-items: center;
            margin-top: 19px;
            .new {
              font-size: 16px;
              font-family: PingFangMedium;
              color: #EC6C01;
              line-height: 21px;
              span {
                font-size: 28px;
              }
              .new_point {
                font-size: 16px;
              }
              .free {
                font-size: 24px;
              }
            }
            .old {
              font-size: 16px;
              font-family: PingFangMedium;
              font-weight: 400;
              text-decoration: line-through;
              color: #999999;
              line-height: 21px;
              margin-left: 9px;
            }
          }
          .button2 {
            text-align: left;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #15b7dd;
            cursor: pointer;
            line-height: 34px;
            margin-top: 32px;
            img {
              width: 16px !important;
              height: 16px !important;
              margin-left: 8px;
            }
          }
          .button {
            width: 134px;
            height: 40px;
            background: #15b7dd;
            border-radius: 2px;
            margin-top: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            &:hover::before {
              left: 100%;
            }
            p {
              margin-left: 5px;
            }
          }
          .button::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: -100%;
            background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, .5), transparent);
            transition: all .5s;
          }
        }
      }
    }
    .contentItem:first-child{
      .white{
        top:2px !important;
      }
    }
    .contentItem:hover {
      position: relative;
      z-index: 2;
      border-bottom: 1px solid #ffffff;
      box-shadow: 0px 4px 10px 1px rgba(93, 131, 167, 0.15);
      >img{
        transform:scale(1.05,1.05)
      }
    }
    .spin {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
// 小屏分辨率内容区呈现1200px布局
@media screen and (max-width: 16000px) {
  .main-box .tabs {
    padding: 0 30px;
    .tabItem{
      padding: 0 25px;
    }
  }
}
</style>
